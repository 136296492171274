import { combineReducers } from "redux";

import * as userDuck from "./user";

// TODO: начал использовать redux с toolkit
// как пример для образца можно использовать папку юзер

export const rootReducer = combineReducers({
  user: userDuck.reducer,
});

export const actions = {
  user: { ...userDuck.thunks, ...userDuck.actions },
};

export const selectors = {
  user: userDuck.selectors,
};
