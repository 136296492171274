import toast from "react-hot-toast";
import axiosInstance from "../../api/axios.instance";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signInThunk = createAsyncThunk(
  "auth/authenticate",
  async () => {
    try {
      const { data } = await axiosInstance.post("/auth/authenticate");
      console.log('START');
      if (data.status === "success") {
        console.log("SUCC", data.apiData);
        return data.apiData;
        
      }
    } catch (error) {
      return toast.error(error.message ? error.message : "Server side error", {
        id: "",
      });
    }
  }
);


export const thunks = {
  authenticate: signInThunk,
};